<template>
  <div class="minbox index">
    <!-- 头部通用区域 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
    <!-- 顶部banner区域 -->
    <div class="boxContentbg" id="index_0">
      <div class="swiper-container swiper1">
        <div class="swiper-wrapper">
          <div
                  class="swiper-slide"
                  v-for="(item, index) in banners"
                  :key="index"

          >
            <a :href="item.linkUrl" target="_blank" class="banner-bg" :style="{background:'url('+item.filePath+')  top center no-repeat',backgroundSize: 'cover' }" >
              <div class="banner">
                <div class="bannerTitle">{{ item.title }}</div>
                <div class="operate">{{item.summary}}</div>
                <div class="seeMore">
                  <div class="seeMore-item">
                    查看更多
                  </div>
                  <div class="seeMore-img">
                    <img
                            src="../../assets/homePageImg/home_banner_more@2x.png"
                            alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <!-- 新闻模板区域 -->
    <div class="boxContentNews">
      <div class="newsCenter">
        <div class="newsTitle">
          <div class="newscore" @click="clickRouter('/news','0')">新闻中心</div>
          <div class="newsTitleTop" @click="clickRouter('/newslist','0')">
            <div class="newsTitleTopExplain">查看全部新闻</div>
            <div class="newsTitleTopImage">
              <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div class="newsContent">
          <div class="newsContentTop">
            <div class="news-item" v-for="(item) in newsArray" :key="item.id" @click="goNews(item.id)">
              <div class="news-img">
                <img :src="item.cover" alt="" />
              </div>
              <div class="news-explain">
               {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="boxContentSol" id="index_1">
      <div class="solution">
        <div class="solutionDescribe">智能化金融服务解决方案</div>
        <div class="solcontent">
          <div>
            随着金融科技爆炸式的发展，华炫鼎盛开始在资金业务领域里应用互联网、大数据、人工智能以及区块链等前沿科技，并从传统的资金系统供应商的角色延伸为金融服务平台的服务商，通过提供各种SaaS和PaaS服务，让我们的金融机构客户省去耗时费力的系统开发阶段，直接应用我们的金融服务平台开展业务，争取市场商机、创造营业收益。
          </div>

        </div>
        <div class="programme">
          <div class="progItem"  @click="goLink('trade')">
            <div class="progItemImage">
              <img src="../../assets/homePageImg/solution1.png" alt="" />
            </div>
            <div class="progItemTitle">
              机构间场外交易平台
            </div>
            <div class="progItemExplain">
              华炫鼎盛根据银行资金业务现在与未来的发展，提供一个闭环生态系，在这个生态系中可以满足银行资金业务几乎所有的场景，这套生态系包含…
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle">查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="progItem" @click="goLink('cooperation')">
            <div class="progItemImage" >
              <img src="../../assets/homePageImg/solution2.png" alt="" />
            </div>
            <div class="progItemTitle">
              产融合作信息化平台
            </div>
            <div class="progItemExplain">
              为响应政府促进金融服务制造强国建设，华炫鼎盛打造了服务于国家产融合作的信息与交易平台，降低金融机构融资风险、提升金融机构放贷意…
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle" >查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="progItem" @click="goLink('gjcr')">
            <div class="progItemImage"  >
              <img src="../../assets/homePageImg/solution3.png" alt="" />
            </div>
            <div class="progItemTitle">
              国家产融合作平台
            </div>
            <div class="progItemExplain">
              2020年7月份，华炫鼎盛在工业和信息化部，以及中国信息通信研究院、中国工业互联网研究院、工业文化发展中心、赛迪研究院等单位的指导和支…
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle" >查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>

        </div>
        <div class="programme line-second" @click="goLink('financialModel')">
          <div class="progItem"  >
            <div class="progItemImage">
              <img src="../../assets/homePageImg/solution4.png" alt="" />
            </div>
            <div class="progItemTitle">
              首创去中心化的工业互联网产业金融…
            </div>
            <div class="progItemExplain">
              华炫鼎盛以金融科技为手段助力国家打造工业互联网产业新生态，助力中国加速实现科技高质量发展，华炫鼎盛以全新理念创新了以数据提升企业资信水…
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle">查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="progItem mr0"  @click="goLink('local')">
            <div class="progItemImage">
              <img src="../../assets/homePageImg/solution5.png" alt="" />
            </div>
            <div class="progItemTitle">
              地方政府综合金融服务平台
            </div>
            <div class="progItemExplain">
              华炫鼎盛自2019年以来，随着完成“国家产融合作平台”、“工业文化产融平台”、大型核心企业“信票流转与融资平台”等国家级与央企级的产融合…
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle" >查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 平台服务 -->
    <div class="boxContentSer" id="index_2">
      <div class="platformServices">
        <div class="platformServicesTitle">平台服务</div>
        <div class="solcontent">
          区块链时代的到来，使得 "网上的数据更真实"、"网上的储存更安全"
          成为一种新时代的信念，区块链正式破解了金融机构信用成本过高的难题。<br />
          基于这个全新的观念，我们打造了一个服务于机构间信息交互的区块链平台(PaaS)服务于金融机构对金融机构、<br />
          金融机构对企业的各种金融交易场景(SaaS)，使其更加安全、更加值得信任
        </div>
        <div class="programme">
          <div class="progItem" @click="clickRouter('/financialRegion','2')">
            <div class="progItemImage">
              <img
                src="../../assets/homePageImg/platform_ser_qkl_01@2x.png"
                alt=""
              />
            </div>
            <div class="progItemTitle" >
              区块链金融服务平台
            </div>
            <div class="progItemExplain">
              基于区块链的安全交易网络平台服务(PaaS)，进行用户认证、信息安全、交易结果不可窜改的目的。应用在交易平台....
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle">查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="progItem"  @click="clickRouter('/mechanism','2')">
            <div class="progItemImage">
              <img
                src="../../assets/homePageImg/platform_ser_jgj_02@2x.png"
                alt=""
              />
            </div>
            <div class="progItemTitle">
              机构间场外交易平台
            </div>
            <div class="progItemExplain">
              "机构间的交易要在基于机构利益的专属交易工具上完成"，基于这个理念，我们打造一个安全且合规的机构间场外交易平台，以便......
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle">查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="progItem" @click="clickRouter('/comprehensive','2')">
            <div class="progItemImage">
              <img
                src="../../assets/homePageImg/platform_ser_zhjr_02@2x.png"
                alt=""
              />
            </div>
            <div class="progItemTitle">
              综合金融服务平台
            </div>
            <div class="progItemExplain">
              通过联盟链将贸金场景链链相连，加上大数据、人工智能的加持，使金融机构敢放贷、愿意放贷，达到金融支持实体经济的目的......
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle">查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 银行资金管理系统解决方案 -->
    <div class="boxContentSol" id="index_7">
      <div class="solution">
        <div class="solutionDescribe">银行资金管理系统解决方案</div>
        <div class="solcontent" style="display: flex;justify-content: center;">
          <div style="width: 926px;">
            经过四十多年的积累，华炫鼎盛在银行资金管理领域有著丰富的产品线，涵盖资金业务的交易管理、风险管理、运营管理、流动性管理以及托管管理等业务。
          </div>
        </div>
        <div class="programme">
          <div class="progItem"  @click="goLink('transaction')">
            <div class="progItemImage">
              <img src="../../assets/homePageImg/home_jypt@2x.png" alt="" />
            </div>
            <div class="progItemTitle">
              交易平台
            </div>
            <div class="progItemExplain">
              资金交易是银行资金业务的发起点，也是华炫鼎盛解决方案的入口，华炫鼎盛在银行与分行间提供结售汇与外汇交易报价平台；...
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle">查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="progItem" @click="goLink('manageSystem')">
            <div class="progItemImage" >
              <img src="../../assets/homePageImg/home_glxt@2x.png" alt="" />
            </div>
            <div class="progItemTitle">
              资金系统
            </div>
            <div class="progItemExplain">
              交易达成之后，银行还可以选择华炫鼎盛资金管理系统进行后续的业务处理以及管理，华炫鼎盛的资金管理系统包含针对资金业务一体化统...
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle" >查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="progItem" @click="goLink('pricingEngine')">
            <div class="progItemImage"  >
              <img src="../../assets/homePageImg/home_djyq@2x.png" alt="" />
            </div>
            <div class="progItemTitle">
              定价引擎
            </div>
            <div class="progItemExplain">
              在华炫鼎盛资金交易平台以及资金业务管理系统背后，都内嵌了强大的定价引擎，可以为多达1700多种本外币金融商品进行定价...
            </div>
            <div class="viewArrow">
              <div class="viewArrowTitle" >查看详情</div>
              <div class="viewArrowMin">
                <img src="../../assets/homePageImg/title_more@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 平台合作 -->
    <div class="boxContentPlatform" id="index_3">
      <div class="cooperationPlatform">
        <div class="cooperationPlatformTitle">国家产融合作平台</div>
        <div class="cooperationPlatformExplain">
          <div>
            国家产融合作平台（以下简称“平台”）是指在工业和信息化部政务一体化平台建设规范下，依托工业和信息化部及其他相关单位数据资源和积累能力，利用新一代信息技术手段,
          </div>
          <div>
            畅通产融信息对接渠道，推动实现政策管理与公共服务线上化、数据分析与信息对接智能化、产融交流与多方合作生态化的非盈利性公共服务平台。
          </div>
          <br>
          <div class="index">
            平台核心功能主要有八个方面：一是通过算法工具，加强工信体系数据分析，实现融资需求智能化对接；二是开展产融线上对接活动，搭建金融和产业信息沟通桥梁；三是推动产融合作试点城市申报、评选工作线上化，宣传推广试点城市典型经验；四是落实我部与金融机构战略合作协议，完善产融合作生态体系建设；五是开展企业上市培育，加强资本市场合作；六是推动金融科技产业协同，利用金融科技手段加强产融合作创新；七是开展线上培训，引导企业强化信用和融资能力建设；八是加强产业投资基金合作，助力制造强国网络强国建设
          </div>
        </div>
        <div class="vadiobox">
          <video
            src="https://crptht.miit.gov.cn/miit/20200930/产融合作平台20200915.mp4"
            loop="loop"
            muted="muted"
            controls="controls"
            disablepictureinpicture="true"
            id="video"
            class="pingtaiVideo"
            style="height: auto;"
          ></video>
        </div>
      </div>
    </div>
    <!-- 支持中心 -->
    <div class="boxContentSupport" id="index_4">
      <div class="supportCenter">
        <div class="supportCenterTitle">支持中心</div>
        <div class="supportCenterExplain">
          如果说产品与平台是客户选择我们作为合作伙伴的关键原因，我们的服务则是让这个合作关系绵延10年以上的决定性因素。<br />
          我们的团队汇集了国内外 FinTech
          领域的专业人才，专家均来自跨国金融企业，有著25年以上的从业经验：技术团队则网罗了具备15年以上实际经开发、<br />
          落地的系统架构师与开发工程师；加上大批具有丰富行业经验的部署实施工程师，使得客户与我们合作过程中倍感安心、舒心与放心。
        </div>
        <div class="maintenanceManagement">
          <div class="manageTop">
            <div class="ment-item">
              <div class="ment-item-img">
                <img
                  src="../../assets/homePageImg/support_zx_01@2x.png"
                  alt=""
                />
              </div>
              <div class="ment-item-term">
                <div class="ment-item-term-top">咨询服务</div>
                <div class="ment-item-term-bottom">
                  提供资金业务的优化建议、资金系统的建置规划、<br>
                  金融商品的估值定价、资金业务的大数据分析与挖掘等服务
                </div>
              </div>
            </div>
            <div class="ment-item">
              <div class="ment-item-img">
                <img
                  src="../../assets/homePageImg/support_yy_03@2x.png"
                  alt=""
                />
              </div>
              <div class="ment-item-term">
                <div class="ment-item-term-top">运营维护</div>
                <div class="ment-item-term-bottom">
                  提供华炫鼎盛系统产品的售后运维服务或服务
                </div>
              </div>
            </div>
          </div>
          <div class="manageBottom">
            <div class="ment-item">
              <div class="ment-item-img">
                <img
                  src="../../assets/homePageImg/support_js_02@2x.png"
                  alt=""
                />
              </div>
              <div class="ment-item-term">
                <div class="ment-item-term-top">技术支持</div>
                <div class="ment-item-term-bottom">
                  提供资金业务系统的技术支持、二次开发、定制开发、<br>
                  基于区块链的交易场景延伸、大数据分析等服务
                </div>
              </div>
            </div>
            <div class="ment-item">
              <div class="ment-item-img">
                <img
                  src="../../assets/homePageImg/support_jy_04@2x.png"
                  alt=""
                />
              </div>
              <div class="ment-item-term">
                <div class="ment-item-term-top">培训教育</div>
                <div class="ment-item-term-bottom">
                  提供外汇、贵金属、固定收益、汇／利率衍生品的估值、<br>
                  定价、报价、交易、风控结核清、头寸管理、资负优化等培训
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 媒体报道 -->
    <div class="boxContentMedia">
      <div class="mediaCoverage">
        <div class="mediaContent">
          <div class="mediaContentTitle">媒体报道专辑</div>
          <div class="mediaContentArrow">
            <div class="mediaContentName" @click="clickRouter('/topicDetailslist','2')">更多专辑</div>
            <div class="mediaContentMin">
              <img
                src="../../assets/homePageImg/title_more_white@2x.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="newsContent">
          <div class="news-item" v-for="item in topicArray" :key="item.id"  @click="goPage('/topicDetailslist',{topicId: item.id})">
            <div class="news-item-images">
              <img :src="item.fileList[0].filePath" alt="" />
            </div>
            <div class="news-item-explain">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="boxContentPartne" id="index_5">
      <div class="cooperativePartner">
        <div class="cooperativePartnerTitle">合作伙伴</div>
        <div class="cooperativePartnerExplain">
          我们的客户遍布大中小型银行、保险公司、资产管理公司以及财务公司。我们珍视每一个伴我们成长的客户，为每一个客户提供专业且周到的服务，<br />
          其中与中国农业银行、中国进出口银行、厦门银行等客户维持长达20年的合作关系，足见客户对我们产品与技术的认可以及对我们服务的赞赏。<br />
          在产业与技术快速更迭的时代里，我们深刻了解众志成城的道理，因此广泛地与包含北航天、北理工、工信部以及中科院等多家一流的科研机构合作，
          大家群策群力，共同为中国未来的金融行业打下坚实的基础
        </div>
        <div class="cooperativeSchool">
          <div class="bankTrem">
            <img src="../../assets/homePageImg/partner_kxy_01@2x.png" alt="" />
          </div>
          <div class="bankTrem">
            <img src="../../assets/homePageImg/partner_lg_02@2x.png" alt="" />
          </div>
          <div class="bankTrem">
            <img src="../../assets/homePageImg/partner_ny_03@2x.png" alt="" />
          </div>
          <div class="bankTrem">
            <img src="../../assets/homePageImg/partner_jck_04@2x.png" alt="" />
          </div>
          <div class="bankTrem">
            <img src="../../assets/homePageImg/partner_xm_05@2x.png" alt="" />
          </div>
        </div>
        <div class="mor-partner" @click=" goLink('partner')">
          更多合作伙伴 >
        </div>
      </div>
    </div>
    <!-- 加入我们 -->
    <div class="boxContentJoin">
      <div class="joinmy">
        <div class="jointop">
          <div class="jointopTitle">加入我们</div>
          <div class="jointopExplain">
             <div>我们致力应用区块链、大数据与机器学习等先进技术于金融机构的外汇、贵金属、固定收益、汇/利率衍生品的交易、风控、结清算、核算等一体化解决方案</div>
             <div>诚邀有志通过大数据、人工智能、区块链技术在金融领域进行创新与发展的男女青年加入我们。</div>
          </div>
        </div>
        <div class="joinContent">
          <div class="contentLeft">
            <div class="contentLeftLine"></div>
            <div class="contentLeftEnglish" @click="clickRouter('/about','6')">JOIN US</div>
            <div class="contentLeftDescribe">
              <div>
                如果您对下列技术在金融领域应用有兴趣且具备相当实力，
                欢迎与我们联系！
              </div>
              <br />
              <div>
                区块链：询报价、交易、对手认证、交易凭证、清算交割等；
                商业智能：用户画像、对手行为分析、报价策略优化等；
                机器学习：信用特征分析、实时交易NLP、流动性管理等；
                智能决策：另类数据、资金一体化决策管支持等
              </div>
            </div>
          </div>
          <div class="contentRight">
            <div class="contentRightLine"></div>
            <div class="contentRightnglish" @click="clickRouter('/about','6')">关于我们</div>
            <div class="contentRightDescribe">
              <div>
                我们是金融行业专业的信息化解决方案与
                RegTech平台提供商，是国内率先于资金业务领域应用区块链、大数据与机器学习技术的高新企业，为银行、保险与大型企业财务公司提供资金业务的交易平台、风险管理系统以及各项业务一体化管理解决方案。
              </div>
              <br />
              <div>服务邮箱：service@topsoft.com.cn</div>
              <div>客服电话：010-83020210</div>
              <div>公司地址：北京市海淀区学院南路62号中关村资本大厦11层</div>
              <br />
            </div>
          </div>
        </div>
        <div class="postbg"></div>
      </div>
    </div>
    <!-- 底部公共区域 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/public/NavBar'
import FooterAll from '../../components/public/footerAll'
import { getBannerList, getNewsList, getTopicList } from '@/api/index'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  data() {
    return {
      activeIndex: '',
      banners: [],
      newsArray: [],
      topicArray: []
    }
  },
  components: {
    Navbar,
    FooterAll,
  },
  created() {
    this.activeIndex = this.$route.query.id
  },
  mounted() {
    this.bannerList()
    this.newsList()
    this.topList()
  },
  methods: {
    topList() {
      getTopicList({
        sort: 'creatTime',
        pageSize: 4,
        pageNumber: 1
      }).then(res => {
        if(res.data.success) {
          this.topicArray = res.data.result.content
        }
      })
    },
    newsList() {
      getNewsList({
        sort: 'creatTime',
        pageSize: 8,
        pageNumber: 1
      }).then(res => {
        if(res.data.success) {
          this.newsArray = res.data.result.content
        }
      })
    },
    goNews(id) {
      this.goPage('/news', { newsId: id})
    },
    goLink(hash) {
      this.$router.push(hash)
    },
    goPage(path, query) {
      this.$router.push({ path: path, query: query})
    },
    bannerList() {
      getBannerList({}).then(res => {
        if(res.data.success) {
          this.banners = res.data.result.content
          this.$nextTick(() => {
            new Swiper('.swiper1', {
              autoplay: true,
              loop: this.banners.length>1,
              pagination: {
                el: '.swiper-pagination',
                clickable :true,
              },
            })
          })
        }
      })
    },
    clickRouter(url) {
      this.$router.push({
        path: `${url}`,
      })
    },
    // goAbout (url) {
    //   this.$router.push({
    //     path: url,
    //     query: {
    //       id: '6'
    //     }
    //   })
    // }
  },
}
</script>
<style lang="scss" scoped>

//  通用的头部
.currencyTitle {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
// 通用的标题
.currencyH1 {
  font-size: 32px;
  font-weight: 600;
  color: #1b314e;
  line-height: 45px;
}
.share {
  width: 280px;
  height: 238px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
  .news-img {
    width: 260px;
    height: 146px;
    padding: 16px 10px 0px 10px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .news-explain {
    /*padding: 12px 10px 20px 10px;*/
    width: 260px;
    height: 44px;
    margin: 12px auto 0;
    font-size: 14px;
    font-weight: 400;
    color: #181818;
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
.programme {
  display: flex;
  padding: 0 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  &.line-second{
    justify-content: center;
    margin-top: 18px;
    .progItem{
      margin-right: 64px;
    }
    .progItem.mr0{
      margin-right: 0;
    }
  }
  .progItem {
    width: 340px;
    cursor: pointer;
    .progItemImage {
      height: 196px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .progItemTitle {
      font-size: 20px;
      font-weight: 600;
      color: #181818;
      line-height: 28px;
      padding: 20px 0 14px 0;
    }
    .progItemExplain {
      // width: 350px;
      height: 66px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
    }
    .viewArrow {
      display: flex;
      padding-top: 30px;
      padding-bottom: 80px;

      .viewArrowTitle {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #0f4790;
        line-height: 20px;
        cursor: pointer;
      }
      .viewArrowMin {
        width: 12px;
        height: 12px;
        padding: 4px 0 4px 6px;

        > img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}

.meto {
  font-size: 14px;
  font-weight: 400;
  color: #181818;
  line-height: 24px;
  padding-top: 24px;
  padding-bottom: 40px;
  text-align: center;
  margin: 0 auto;
}
.minbox {
  min-width: 1180px; // 设置的最小宽度，小于1180px时，会出现滚动条
  width: 100%;
  background: #fafafa;
  > div > div {
    width: 1180px;
    margin: 0 auto;
  }
  .boxContent {
    width: 100%;
    height: 68px;
    margin: auto;
    display: flex;
    justify-content: center;
    background: #ffffff;
  }
  .boxContentbg {
    width: 100%;
    height: 480px;
    .banner-bg {
      display: block;
      height: 480px;
    }
    .banner {
      color: #fff;
      margin: 0 auto;
      width: 1180px;
      .bannerTitle {
        font-size: 42px;
        font-weight: 400;
        height: 59px;
        padding-top: 120px;
      }
      .operate {
        font-size: 18px;
        font-weight: 400;
        padding-top: 30px;
        height: 25px;
      }
      .seeMore {
        width: 110px;
        height: 32px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        margin-top: 60px;
        display: flex;
        align-items: center;
        > .seeMore-item {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          padding-left: 16px;
        }
        > .seeMore-img {
          width: 16px;
          height: 10px;
          padding-left: 8px;
          > img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
  }
  .boxContentNews {
    background: url('../../assets/homePageImg/news_bg@2x.png');
    background-size: 100% 100%;
    .newsCenter {
      padding-top: 48px;
      .newsTitle {
        @extend .currencyTitle;
        position: relative;
        .newscore {
          @extend .currencyH1;
          cursor: pointer;
          margin: 0 auto;
        }
        .newsTitleTop {
          display: flex;
          font-size: 14px;
          font-weight: 400;
          color: #0f4994;
          line-height: 20px;
          cursor: pointer;
          position: absolute;
          right: 0;
          .newsTitleTopImage {
            width: 12px;
            height: 12px;
            padding: 2px 0 2px 4px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .newsContent {
        padding-top: 32px;
        padding-bottom: 80px;
        .newsContentTop{
          width: 1200px;
          .news-item {
            cursor: pointer;
            display: inline-block;
            margin: 0px 20px 20px 0;
            vertical-align: top;
            @extend .share;
            &:hover {
              box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.15);
              .news-explain{
                color: #0F4994;
                /*font-weight: 600;*/
              }
            }
          }
        }
        .newsContentBottom {
          display: flex;
          justify-content: space-between;
          .news-item {
            @extend .share;
          }
        }
        .newsContentBottom {
          margin-top: 20px;
        }
      }
    }
  }
  .boxContentSol {
    width: 100%;
    .solution {
      .solutionDescribe {
        @extend .currencyTitle;
        @extend .currencyH1;
        padding-top: 48px;
        cursor: pointer;
      }
      .solcontent {
        // width: 926px;
        height: 72px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(18, 18, 18, 0.8);
        line-height: 24px;
        padding-top: 24px;
        padding-bottom: 40px;
        text-align: center;
        margin: 0 auto;
      }
      .programme {
        .progItem {
          position: relative;
          &:hover {
            .progItemTitle{
              color: #0F4994;
            }
            .progItemExplain{
              color: #666666;
            }
            &::after {
              content: '';
              position: absolute;
              width: 372px;
              height: 446px;
              left: -16px;
              top: -16px;
              background: rgba(0, 0, 0, 0);
              box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.09);
            }
          }
          .progItemExplain {
            color: rgba(99, 99, 99, 0.6);
          }
        }
      }
    }
  }
  .boxContentSer {
    width: 100%;
    background: url('../../assets/homePageImg/platform_service_bg.png')
      no-repeat center center;
    .platformServices {
      .platformServicesTitle {
        @extend .currencyTitle;
        @extend .currencyH1;
        padding-top: 48px;
        cursor: pointer;
      }
      .solcontent {
        // width: 926px;
        height: 72px;
        font-size: 14px;
        font-weight: 400;
        color: #181818;
        line-height: 24px;
        padding-top: 24px;
        padding-bottom: 40px;
        text-align: center;
        margin: 0 auto;
      }
      .viewArrowTitle {
        color: #0f4994 !important;
      }
    }
    .progItemTitle {
      cursor: pointer;
    }
  }
  .boxContentPlatform {
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
    .cooperationPlatform {
      padding-bottom: 80px;
      .cooperationPlatformTitle {
        @extend .currencyTitle;
        @extend .currencyH1;
        padding-top: 48px;

      }
      .cooperationPlatformExplain {
        width: 1180px;
        height: 144px;
        font-size: 14px;
        font-weight: 400;
        color: #181818;
        line-height: 24px;
        line-height: 24px;
        padding-top: 24px;
        padding-bottom: 40px;
        text-align: center;
        margin: 0 auto;
      }
      .vadiobox video {
        width: 100%;
        height: 664px;
      }
    }
  }
  .boxContentSupport {
    .supportCenter {
      .supportCenterTitle {
        @extend .currencyTitle;
        @extend .currencyH1;
        padding-top: 48px;

      }
      .supportCenterExplain {
        // width: 954px;
        height: 72px;
        font-size: 14px;
        font-weight: 400;
        color: #181818;
        line-height: 24px;
        line-height: 24px;
        padding-top: 24px;
        text-align: center;
        margin: 0 auto;
      }
      .maintenanceManagement {
        padding-top: 72px;
        padding-bottom: 80px;
        .manageTop,
        .manageBottom {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .ment-item {
            box-sizing: border-box;
            display: flex;
            width: 590px;
            height: 136px;
            padding: 26px 0 26px 46px;
            &:hover{
              background: #fff;
              box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.09);
              .ment-item-term-top{
                color: #0F4790 !important;
              }
            }
            .ment-item-img {
              width: 84px;
              height: 84px;
              padding: 2px 46px 2px 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .ment-item-term {
              .ment-item-term-top {
                // width: 80px;
                height: 28px;
                font-size: 20px;
                font-weight: 600;
                color: #181818;
                line-height: 28px;
                padding-bottom: 16px;
              }
              .ment-item-term-bottom {
                width: 380px;
                height: 44px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 22px;
              }
            }
          }
        }
        .manageBottom {
          margin-top: 66px;
        }
      }
    }
  }
  .boxContentMedia {
    width: 100%;
    background: url('../../assets/homePageImg/media_bg.png') no-repeat center
      center;
    .mediaCoverage {
      padding-top: 48px;
      padding-bottom: 80px;
      .mediaContent {
        position: relative;
        @extend .currencyTitle;
        .mediaContentTitle {

          @extend .currencyH1;
          margin: 0 auto;
          color: #ffffff;

        }
        .mediaContentArrow {
          display: flex;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          position: absolute;
          right: 0;
          cursor: pointer;
          .mediaContentMin {
            width: 12px;
            height: 12px;
            padding: 2px 0 2px 4px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .newsContent {
        width: 1200px;
        padding-top: 40px;
        .news-item {
          display: inline-block;
          margin-right: 20px;
          cursor: pointer;
          width: 280px;
          height: 238px;
          background: #ffffff;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
          margin-right: 20px;
          vertical-align: top;
          &:hover {
            box-shadow: 0px 10px 29px 0px rgba(3, 25, 57, 0.8);
            .news-item-explain{
              color: #0F4994;
              /*font-weight: 600;*/
            }
          }
          .news-item-images {
            width: 260px;
            height: 146px;
            padding: 16px 10px 0 10px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          .news-item-explain {
            width: 260px;
            height: 44px;
            margin: 12px auto 0;
            font-size: 14px;
            font-weight: 400;
            color: #181818;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
    }
  }
  .boxContentPartne {
    .mor-partner{
      text-align: center;
      cursor: pointer;
      padding-bottom: 80px;
      color: #1b314e;
      font-size: 14px;
      line-height: 20px;
      margin-top: 20px;
    }
    .cooperativePartner {
      .cooperativePartnerTitle {
        @extend .currencyTitle;
        @extend .currencyH1;
        padding-top: 48px;

      }
      .cooperativePartnerExplain {
        width: 922px;
        height: 96px;
        padding-bottom: 0px;
        @extend .meto;
      }
      .cooperativeSchool {
        padding-top: 72px;

        display: flex;
        justify-content: space-between;
        .bankTrem {
          width: 220px;
          height: 132px;
          padding-right: 20px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .boxContentJoin {
    width: 100%;
    background: url('../../assets/homePageImg/aboutus_bg_big.png');
    background-size: 100% 100%;
    position: relative;
    .joinmy {
      .jointop {
        .jointopTitle {
          @extend .currencyTitle;
          @extend .currencyH1;
          padding-top: 48px;
          height: 45px;

        }
        .jointopExplain {
          text-align: center;
          height: 48px;
          @extend .meto;
          padding-bottom: 72px;
        }
      }
      .joinContent {
        display: flex;
        position: relative;
        z-index: 1;
        .contentLeft {
          width: 464px;
          height: 520px;
          background: url('../../assets/homePageImg/aboutus_bg1@2x.png');
          background-size: 100% 100%;
          margin-left: 94px;
          .contentLeftLine {
            width: 24px;
            height: 3px;
            background: #ffffff;
            margin: 70px 0 0 32px;
          }
          .contentLeftEnglish {
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
            padding-top: 141px;
            padding-left: 32px;
            cursor: pointer;
          }
          .contentLeftDescribe {
            height: 168px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 24px;
            padding: 16px 32px 0px 32px;
          }
        }
        .contentRight {
          width: 529px;
          height: 520px;
          background: #ffffff;
          box-shadow: 0px 24px 40px 0px rgba(0, 70, 255, 0.05);
          .contentRightLine {
            width: 24px;
            height: 3px;
            background: #181818;
            margin: 70px 0 0 40px;
          }
          .contentRightnglish {
            height: 28px;
            font-size: 20px;
            font-weight: 600;
            color: #181818;
            line-height: 28px;
            padding: 141px 0 0 40px;
            cursor: pointer;
          }
          .contentRightDescribe {
            height: 192px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            padding: 16px 40px 0px 40px;
          }
        }
      }
      .postbg {
        width: 1180px;
        height: 437px;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        border-radius: 4px 4px 0px 0px;
        position: absolute;
        top: 322px;
      }
    }
  }
  .boxContentFooter {
    background: #00244f;
  }
  .progItem {
    position: relative;
    z-index: 10;
    &:hover {
      .progItemTitle{
        color: #0F4994;
        font-weight: 600;
      }
      .progItemExplain{
        color: #666666;
      }
      &::after {
        content: '';
        position: absolute;
        width: 382px;
        height: 446px;
        left: -16px;
        top: -16px;
        background: #fff;
        box-shadow: 0px 3px 20px 0px rgb(0 0 0 / 9%);
        z-index: -1;
      }
    }
  }
}
</style>
<style lang="scss">
  .minbox.index{
    .swiper-container{
      width: 100%;
      height: 100%;
    }
    .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
      bottom: 24px;
    }
    .swiper-pagination-bullet{
      border-radius: 0;
      width: 40px;
      height: 4px;
      background: rgba(255, 255, 255, 1);
      opacity: .4;
    }
    .swiper-pagination-bullet-active{
      background: rgba(255, 255, 255, 1);
      opacity: 1;
    }

  }
</style>
